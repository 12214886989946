import $ from 'jquery';

/** import local dependencies */
import './webflow.js';
//import 'slick-carousel';

//import Headroom from "headroom.js";

/**
 * Anonymous function is autoexecutable.
 */
(function () {

  let initialHash = location.hash;
  const $html = document.querySelector('html');

  function openModal(hash, start){
    closeModal();
    let $el = document.querySelector(hash);
    if ( $el && $el.classList.contains('modal') ) {
      $html.classList.add('overflow');
      $el.classList.add('active');
      
    }
  }

  function closeModal(){
    document.querySelectorAll('.modal').forEach($el => {
      $html.classList.remove('overflow');
      $el.classList.remove('active');
    });
  }

  function cleanHash(){
    history.pushState(null, null, '#');
  }

  window.addEventListener('hashchange', () => {
    let hash = window.location.hash;
    console.log(hash);
    if ( hash !== '' ) {
      openModal(hash, false);
    } else {
      closeModal();
    }
  });

  var userSelection = document.getElementsByClassName('icon-plus-link');

  for(let i = 0; i < userSelection.length; i++) {
    userSelection[i].addEventListener("click", function(e) {
      window.location.hash = this.hash;
      e.preventDefault();
      e.stopPropagation();
    })
  }


  if (initialHash.length > 0) {
    openModal(initialHash, true);
  }

  document.addEventListener('keydown', function(e) {
    if (e.which === 27) {
      closeModal();
      cleanHash();
    }
  });

  if($('.modal-close').length > 0){
    $('.modal-close').on('click', function(e){
      e.preventDefault;
      closeModal();
      cleanHash();
    });
  }

  window.onclick = function(event) {
    if(event.target.classList.contains('modal')){
      closeModal();
      cleanHash();
    }
  }

  // Get all links on the page
  const links = document.querySelectorAll('a');

  // Add click event listener to each link
  links.forEach(link => {
    link.addEventListener('click', e => {
      
      // Prevent default link behavior
      //e.preventDefault();
      
       // Get the href attribute
      const href = e.target.getAttribute('href');

      // Extract the hash part using a regular expression
      const hashMatch = href.match(/#(.*)$/);

      if(hashMatch){

        // Get the extracted hash 
        const hash = hashMatch[1];
        
        // Optionally navigate to the hash 
        window.location.hash = hash;
      }

    });
  });

  if($('.more-information-toggle').length > 0){
    setTimeout(function() { 
      $('.more-information-toggle').trigger('click');
    }, 1000);
  }

  //filters tabs
  var tabs = $('.filter-tab');
  if(tabs.length > 0){
    tabs.on('click',function(){
      $('.filter-tab').removeClass('active');
      $(this).addClass('active');
      var tab_type = $(this).data('content');
      $('.filter-content').removeClass('active');
      $('.filter-content[data-content="'+tab_type+'"]').addClass('active');
    });
  }

})();

document.addEventListener('DOMContentLoaded', function () {
  const ordenSelect = document.getElementById('order');
  const form = document.getElementById('order-properties');

  if(ordenSelect){
    ordenSelect.addEventListener('change', function () {
        const selectedValue = ordenSelect.value;
        const currentUrl = new URL(window.location.href);

        currentUrl.searchParams.set('order', selectedValue);
        window.location.href = currentUrl.href;
    });
  }
});

document.addEventListener( 'wpcf7mailsent', function( event ) {
  if(event.detail.contactFormId == 695 || event.detail.contactFormId == 5 || event.detail.contactFormId == 697 || event.detail.contactFormId == 696){
    location = window.location.protocol+'//'+window.location.host+'/thank-you/';
  }else if(event.detail.contactFormId == 1140 || event.detail.contactFormId == 1142 || event.detail.contactFormId == 1141 || event.detail.contactFormId == 1139){
    location = window.location.protocol+'//'+window.location.host+'/es/gracias/';
  }else if(event.detail.contactFormId == 277 || event.detail.contactFormId == 1275 || event.detail.contactFormId == 1287 || event.detail.contactFormId == 1288 || event.detail.contactFormId == 1289 || event.detail.contactFormId == 1290){
    document.querySelector('.before-download').classList.add('fade-out', 'shrink');
    document.querySelector(".after-download").classList.add('fade-in', 'shrink');
    setTimeout(() => {
      document.querySelector(".ebd_results").classList.add('show');
    }, 2000);
    
  }
  document.cookie = 'wbSubmittedForm=true; path=/';
}, false );
